export let SplitContentAlignment;
(function (SplitContentAlignment) {
    SplitContentAlignment["CENTRE"] = "centre";
    SplitContentAlignment["LEFT"] = "left";
    SplitContentAlignment["RIGHT"] = "right";
})(SplitContentAlignment || (SplitContentAlignment = {}));
export let SplitContentLayout;
(function (SplitContentLayout) {
    SplitContentLayout["STANDARD"] = "standard";
    SplitContentLayout["LARGE"] = "large";
})(SplitContentLayout || (SplitContentLayout = {}));
